//######################################################################
//######################################################################

//                          HEADER

//######################################################################
//######################################################################
// MEDIA QUERY
@media only screen and (max-width: $break-3) {
  .nav-open {
    height: 100vh;
    overflow: hidden;
    // padding-right: 15px;
  }
}

.header {
  @extend %row;

  & {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
    background-color: white;
    z-index: 100;
  }

  .scroll-down & {
    transform: translateY(-100%);
  }

  &__container {
    @extend %container;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem 0;
  }

  &__home-link {
    img {
      width: 15.7rem;
      height: auto;

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        width: 12rem;
      }
    }
  }

  &__menu {
    @media only screen and (max-width: $break-3) {
      position: fixed;
      width: 100%;
      transform: translateX(100%);
      height: calc(100vh - 9.8rem);
      top: 9.8rem;
      right: 0;
      overflow-x: hidden;
      background-color: honeydew;
      z-index: 10;
      // transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
      visibility: hidden;

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        top: 11.8rem;
        height: calc(100vh - 11.8rem);
      }

      &.with-transition {
        transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
      }

      .nav-open & {
        transform: translateX(0%);
        visibility: visible;
        display: initial;
        transition: transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
      }
    }
  }

  &__mobile-nav {
  }
}

.menu {
  display: flex;
  column-gap: 4rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 8rem;
    margin-left: 3.6rem;
    // margin-right: 3.6rem;
    margin-top: 12rem;
  }

  &__item {
  }

  .main-nav-link {
    @extend %menu;
    display: inline-block;
    transform: scale(1);
    transition: all 0.3s ease-in-out;

    // MEDIA QUERY
    @media only screen and (max-width: $break-3) {
      font-size: 4rem;
    }

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      font-size: 2.8rem;
    }

    @media (hover: hover) {
      &:hover {
        color: green;
        transform: translateY(-3px);
      }
    }
  }
}

.mobile-nav__btn {
  display: none;

  // MEDIA QUERY
  @media only screen and (max-width: $break-3) {
    display: block;
    z-index: 11;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-9) {
    margin-right: 1rem;
  }
}

.mobile-nav__icon {
  height: 3rem;
  width: 3rem;
  color: $color-text;
  transition: all 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.15);
    }
  }

  &--close {
    display: none;

    .nav-open & {
      display: block;
    }
  }

  .nav-open &--open {
    display: none;
  }
}

main {
  margin-top: 14.5rem;
  // margin-top: 8.6rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-6) {
    margin-top: 11.8rem;
  }
}

//######################################################################
//######################################################################

//                          CTA

//######################################################################
//######################################################################

.cta {
  @extend %row;

  &__container {
    @extend %container;
    display: flex;
    flex-direction: column;
    align-items: center;

    // display: grid;
    // grid-template-columns: repeat(2, minmax(0, 1fr));
    // justify-items: start;

    padding: 7.2rem 0;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-items: start;
  }

  .label {
    text-align: center;
  }

  .heading--big {
    // grid-row: 2 / 3;
    // grid-column: 1 / 3;

    // margin-top: 2.8rem;
    margin-bottom: 1.6rem;
    text-align: center;
    // width: 90%;
  }

  .paragraph--big {
    margin-bottom: 4.8rem;
    text-align: center;
  }

  .img-container {
    aspect-ratio: 1;
    width: 80%;
    background-color: honeydew;
    // background-color: palegreen;
    // background-color: papayawhip;

    // MEDIA QUERY
    @media only screen and (max-width: $break-8) {
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .cta__links {
    margin-bottom: 10rem;
    display: flex;
    column-gap: 2.4rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-7) {
      flex-direction: column;
      column-gap: 0;
      row-gap: 1.8rem;
    }
  }

  .btn {
    &:nth-child(2) {
    }
  }

  p {
    width: 50%;
    margin-top: 6rem;
  }
}

//######################################################################
//######################################################################

//                          ABOUT

//######################################################################
//######################################################################

.about {
  @extend %section;

  &__row-1 {
    @extend %row;
    margin-bottom: 6rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        row-gap: 4.8rem;
      }
    }

    .img-container {
      aspect-ratio: 4 / 3;
      background-color: honeydew;
      grid-column: 1 / 6;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 1/8;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/10;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .paragraph {
      grid-column: 8 / 12;
      margin-top: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        grid-column: 8 / 13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 5 / 13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 3/13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      span {
        font-weight: 600;
      }
    }
  }

  &__row-2 {
    @extend %row;
    margin-bottom: 16rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-rows: repeat(2, min-content);
        row-gap: 4.8rem;
      }
    }

    .img-container {
      aspect-ratio: 1;
      background-color: honeydew;
      grid-column: 5 / 12;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 3/13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .paragraph {
      grid-column: 1 / 5;
      align-self: center;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 1 / 9;
        grid-row: 2;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/10;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }
    }
  }

  &__row-3 {
    @extend %row;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;
    }

    .quote {
      grid-column: 1 / 13;
      width: 80%;
      margin: 0 auto;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 90%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 100%;
      }
    }

    .paragraph {
      grid-column: 4 / 13;
      align-self: center;
      margin-top: 10rem;
      //   text-align: center;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 2 / 13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1 / 13;
      }
    }
  }

  &__row-4 {
    @extend %row;
    margin-top: 10rem;
    // margin-bottom: 16rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;
      row-gap: 5.6rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .img-container {
      aspect-ratio: 1;
      background-color: honeydew;
      grid-column: 3 / 11;
      grid-row: 1;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }
    }

    .paragraph {
      grid-column: 1 / 9;
      grid-row: 2;
      align-self: center;
      margin-top: 6rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        grid-column: 1 / 12;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      span {
        font-weight: 700;
      }
    }
  }
}

//######################################################################
//######################################################################

//                          HEBERGEMENT

//######################################################################
//######################################################################

.lieu {
  @extend %section;

  &__row-1 {
    @extend %row;
    margin-bottom: 6rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        row-gap: 4.8rem;
      }
    }

    .img-container {
      aspect-ratio: 4 / 3;
      background-color: honeydew;
      grid-column: 1 / 6;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 1/8;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/10;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .paragraph {
      grid-column: 8 / 12;
      margin-top: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        grid-column: 8 / 13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 5 / 13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 3 / 13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }
    }
  }

  &__row-2 {
    @extend %row;
    margin-bottom: 16rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-rows: repeat(2, min-content);
        row-gap: 4.8rem;
      }
    }

    .img-container {
      aspect-ratio: 1;
      background-color: honeydew;
      grid-column: 5 / 12;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 3/13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .paragraph {
      grid-column: 1 / 5;
      align-self: center;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 1 / 9;
        grid-row: 2;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/10;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }
    }
  }

  &__row-3 {
    @extend %row;
    margin-bottom: 16rem;

    .row__container {
      @extend %container;
    }

    .quote {
      width: 80%;
      margin: 0 auto;
      margin-bottom: 6rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 90%;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 100%;
      }
    }

    .img-container {
      aspect-ratio: 16 / 9;
      background-color: honeydew;
      width: 80%;
      margin: 0 auto;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .paragraph {
      width: 80%;
      margin: 0 auto;
      margin-top: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        width: 100%;
      }
    }
  }

  &__row-4 {
    @extend %row;
    margin-bottom: 6rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-rows: repeat(2, min-content);
        row-gap: 4.8rem;
      }
    }

    .img-container {
      aspect-ratio: 4 / 3;
      background-color: honeydew;
      grid-column: 1 / 6;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 1 / 9;
        grid-row: 1;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/10;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .paragraph {
      grid-column: 8 / 12;
      margin-top: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 1 / 9;
        grid-row: 2;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/10;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }
    }
  }

  &__row-5 {
    @extend %row;
    margin-bottom: 16rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-template-rows: repeat(2, min-content);
        row-gap: 4.8rem;
      }
    }

    .img-container {
      aspect-ratio: 16 / 9;
      background-color: honeydew;
      grid-column: 5 / 12;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 5 / 13;
        grid-row: 1;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 3/13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .paragraph {
      grid-column: 1 / 5;
      align-self: center;

      // MEDIA QUERY
      @media only screen and (max-width: $break-5) {
        grid-column: 5 / 13;
        grid-row: 2;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 3/13;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }
    }
  }

  &__row-6 {
    @extend %row;
    margin-bottom: 16rem;

    .row__container {
      @extend %container;

      display: grid;
      grid-template-columns: repeat(12, minmax(0, 1fr));
      column-gap: 2.4rem;
    }

    .quote {
      grid-column: 3 / 12;
      text-align: start;
      margin-bottom: 3.2rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        grid-column: 2 / 12;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-7) {
        grid-column: 1/13;
      }
    }

    .paragraph {
      grid-column: 3 / 10;
      margin-bottom: 8rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-4) {
        grid-column: 3 / 12;
      }

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        grid-column: 1 / 13;
      }
    }

    .gallery {
      grid-column: 1 / 13;

      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(7, min(12.6rem, 9vw));
      grid-gap: 1.5rem;

      // MEDIA QUERY
      @media only screen and (max-width: $break-6) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, min-content);
      }

      &__item {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        background-color: honeydew;

        &--1 {
          grid-row: 1 / 3;
          grid-column: 1 / 3;

          // MEDIA QUERY
          @media only screen and (max-width: $break-6) {
            grid-row: auto;
            grid-column: 1 / 2;
            // aspect-ratio: 1;
          }
        }

        &--2 {
          grid-row: 3 / 6;
          grid-column: 1 / 3;

          // MEDIA QUERY
          @media only screen and (max-width: $break-6) {
            grid-row: auto;
            grid-column: 1 / 2;
          }
        }

        &--3 {
          grid-row: 6 / 8;
          grid-column: 1 / 3;

          // MEDIA QUERY
          @media only screen and (max-width: $break-6) {
            grid-row: auto;
            grid-column: 1 / 2;
          }
        }

        &--4 {
          grid-row: 1 / 4;
          grid-column: 3 / 6;

          // MEDIA QUERY
          @media only screen and (max-width: $break-6) {
            grid-row: auto;
            grid-column: 1 / 2;
          }
        }

        &--5 {
          grid-row: 4 / 8;
          grid-column: 3 / 6;

          // MEDIA QUERY
          @media only screen and (max-width: $break-6) {
            grid-row: auto;
            grid-column: 1 / 2;
          }
        }

        &--6 {
          grid-row: 1 / 3;
          grid-column: 6 / 9;

          // MEDIA QUERY
          @media only screen and (max-width: $break-6) {
            grid-row: auto;
            grid-column: 1 / 2;
          }
        }

        &--7 {
          grid-row: 3 / 8;
          grid-column: 6 / 9;

          // MEDIA QUERY
          @media only screen and (max-width: $break-6) {
            grid-row: auto;
            grid-column: 1 / 2;
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &__img {
        // width: 100%;
        // height: 100%;
        // object-fit: cover; // only works with width and height defined. It works relatively to its container gallery__item
        // display: block; // otherwise it has some white space after it because it act as an inline element
      }
    }
  }
}

//######################################################################
//######################################################################

//                          CONTACT

//######################################################################
//######################################################################

.contact {
  @extend %section;
  //   background-color: lightgoldenrodyellow;
  //   background-color: linen;
  //   background-color: lavender;
  //   background-color: blanchedalmond;
  background-color: honeydew;
  //   height: 100vh;
  padding-bottom: 40rem;

  &__row-1 {
    @extend %row;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: fit-content;
    // row-gap: 4.8rem;

    & > * {
      padding: 2.4rem 0;
      position: relative;
    }
  }

  .row__container {
    @extend %container;

    padding-top: 6rem;
  }

  p:first-child {
    font-weight: 700;
  }

  p {
    display: inline-block;
  }

  .adresse {
    display: flex;
    flex-direction: column;
    align-items: start;
    row-gap: 1rem;
  }

  .tel {
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 3rem;
      height: 1px;
      background-color: black;
    }
  }

  .mail {
    width: 100%;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 3rem;
      height: 1px;
      background-color: black;
    }
  }
}

//######################################################################
//######################################################################

//                          FOOTER

//######################################################################
//######################################################################
.footer {
  @extend %row;
  //   background-color: $color-grey--light;

  .footer-content {
    @extend %container;
    padding: 3.2rem 0;
    text-align: center;
  }

  .paragraph {
    display: flex;
    justify-content: center;
    font-weight: 300;
    // font-size: 1.6rem;

    // MEDIA QUERY
    @media only screen and (max-width: $break-6) {
      flex-direction: column;
    }

    & span:not(:last-child) {
      margin-right: 3rem;
    }

    & span:nth-child(even) {
      &::after {
        content: "\007C";

        // MEDIA QUERY
        @media only screen and (max-width: $break-6) {
          content: "\2022";
        }
      }
    }
  }
}
