//######################################################################
//######################################################################

//                          REUSABLE COMPONENTS

//######################################################################
//######################################################################

.btn {
  @extend %btn;

  display: inline-block;
  background-color: limegreen;
  color: black;
  padding: 1.6rem 2.8rem;
  border-radius: 0.8rem;
  transition: all 0.3s ease-out;

  &:hover {
    // background-color: mediumseagreen;
    // background-color: #40be40;
    // color: white;
    transform: translateY(-4px);
  }
}

.btn-2 {
  @extend %btn;

  display: inline-block;
  padding: 1.6rem 2.8rem;
  border-radius: 6rem;
  background-color: transparent;
  border: 1px solid $color-text;
  color: $color-text;
  transition: all 0.3s ease-out;

  &:hover {
    // background-color: mediumseagreen;
    // background-color: #0f940f;
    // color: white;
    transform: translateY(-4px);
  }
}

.section__title {
  @extend %row;

  margin-bottom: 6rem;
}

.list {
  padding-left: 6rem;
  padding-top: 4rem;

  // MEDIA QUERY
  @media only screen and (max-width: $break-8) {
    padding-left: 4rem;
  }

  &__item:not(:last-child) {
    margin-bottom: 1.2rem;
  }
}

//######################################################################
//######################################################################

//                          ANIMATIONS

//######################################################################
//######################################################################
