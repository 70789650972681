//######################################################################
//######################################################################

//                          COLORS

//######################################################################
//######################################################################

$primary-color: hsl(251, 80%, 96%);
$secondary-color: hsl(168, 76%, 91%);
$color-text: #222222;
$color-text--var-2: #3500fa;
$body-color: #fbfbfb;

$secondary-color--var-1: hsl(168, 70%, 75%);
$secondary-color--var-2: hsl(168, 76%, 86%);
$primary-color--var-1: hsl(251, 80%, 88%);
$tertiary-color: #fcf7f0;

$color-squares: #00ebbf;
$color-grey: #e6e6e6;
$color-grey--light: #f5f5f7;

//######################################################################
//######################################################################

//                          VARIABLES

//######################################################################
//######################################################################
// SASS VARIABLES
$break-1: 80em; // 1280px
$break-2: 73.125em; // 1170px
$break-3: 64em; // 1024px
$break-4: 60em; // 960px
$break-5: 56.25em; // 900px
$break-6: 43.75em; // 700px
$break-7: 37.5em; // 600px
$break-8: 31.25em; // 500px
$break-9: 26.25em; // 420px
$break-10: 23.125em; // 370px
// $break-9: 21.875em; // 350px

:root {
  --body-horizontal-padding: 3vw;
  --section-padding-top: 6rem;
  --section-padding-bottom: 7.2rem;
}

//######################################################################
//######################################################################

//                          FONTS

//######################################################################
//######################################################################

//######################################################################
//######################################################################

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-black-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-blackitalic-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-blackitalic-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-bold-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-bolditalic-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-extrabold-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-extrabolditalic-webfont.woff2")
      format("woff2"),
    url("../fonts/poppins/poppins-extrabolditalic-webfont.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-regular-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-italic-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-italic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-light-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-lightitalic-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-lightitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-medium-webfont.woff2") format("woff2"),
    url("../fonts/poppins/poppins-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/poppins-mediumitalic-webfont.woff2")
      format("woff2"),
    url("../fonts/poppins/poppins-mediumitalic-webfont.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/satoshi/Satoshi-Bold.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Bold.woff") format("woff"),
    url("../fonts/satoshi/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/satoshi/Satoshi-Light.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Light.woff") format("woff"),
    url("../fonts/satoshi/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/satoshi/Satoshi-Medium.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Medium.woff") format("woff"),
    url("../fonts/satoshi/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi";
  src: url("../fonts/satoshi/Satoshi-Regular.woff2") format("woff2"),
    url("../fonts/satoshi/Satoshi-Regular.woff") format("woff"),
    url("../fonts/satoshi/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

//######################################################################
//######################################################################

//                          TYPOS

//######################################################################
//######################################################################

%menu {
  font-family: "poppins";
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 0.5px;
  text-rendering: geometricPrecision;
}

%paragraph {
  font-family: "poppins";
  font-weight: 400;
  font-size: 2rem;
  text-rendering: geometricPrecision;

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    font-size: 1.8rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    font-size: 1.6rem;
  }
}

.paragraph {
  @extend %paragraph;
}

.paragraph--big {
  font-family: "poppins";
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: -0.2px;
  text-rendering: geometricPrecision;

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    font-size: 2.2rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    font-size: 2rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-7) {
    font-size: 1.8rem;
  }
}

.heading--big {
  font-family: "satoshi";
  font-weight: 700;
  font-size: 12rem;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 0px;
  text-rendering: geometricPrecision;

  // MEDIA QUERY
  @media only screen and (max-width: $break-1) {
    font-size: 10rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    font-size: 8rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    font-size: 7.2rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-6) {
    font-size: 6.4rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-7) {
    font-size: 5.6rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-8) {
    font-size: 4.6rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-9) {
    font-size: 4rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-10) {
    font-size: 3.6rem;
  }
}

%btn {
  font-family: "poppins";
  font-weight: 400;
  font-size: 1.8rem;
  text-rendering: geometricPrecision;

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    font-size: 1.6rem;
  }
}

.label {
  font-family: "satoshi";
  font-weight: bold;
  font-size: 2.8rem;
  color: mediumseagreen;
  // text-transform: uppercase;
  text-rendering: geometricPrecision;
}

.section__heading {
  @extend %container;

  font-family: "satoshi";
  font-weight: 700;
  font-size: 5.6rem;
  text-rendering: geometricPrecision;
  text-transform: uppercase;
  color: black;
  position: relative;

  // MEDIA QUERY
  @media only screen and (max-width: $break-4) {
    font-size: 4.8rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    font-size: 4rem;
  }

  &::after {
    content: "";
    // position: absolute;
    // bottom: -2rem;
    margin-top: 2rem;
    display: block;
    width: 10rem;
    height: 2px;
    background-color: grey;
  }
}

.quote {
  font-family: "satoshi";
  font-size: 6rem;
  font-weight: 500;
  text-align: center;
  text-rendering: geometricPrecision;
  line-height: 1.2;

  // MEDIA QUERY
  @media only screen and (max-width: $break-5) {
    font-size: 4.8rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-7) {
    font-size: 4rem;
  }

  // MEDIA QUERY
  @media only screen and (max-width: $break-9) {
    font-size: 3.6rem;
  }
}

//######################################################################
//######################################################################

//                          RESET

//######################################################################
//######################################################################

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px   10 / 16 = 62.5%
  scroll-behavior: smooth;

  /* @media only screen and (max-width: $break-1) {
    font-size: 46.3%;
  } */
}

body {
}

a {
  text-decoration: none;
  color: currentColor;
}

ul {
  list-style: none;
}

picture {
  display: block;
}

img {
  display: block;
}

input {
  outline: none;
  border: none;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}

//######################################################################
//######################################################################

//                          UTILITIES

//######################################################################
//######################################################################

%section {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);
}

%row {
  padding: 0 var(--body-horizontal-padding);
}

%container {
  max-width: 150rem;
  margin: 0 auto;
}

%two-cols {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 2.4rem;
  align-items: center;
}

%ct-centered-it-start {
  display: grid;
  justify-content: center;
  justify-items: start;
  align-items: start;
  align-content: start;
}

// TODO : compare smooth scrolling using javascript
// TODO : set all the links
// TODO : try lazy loading image with HTML / CSS
