:root {
  --body-horizontal-padding: 3vw;
  --section-padding-top: 6rem;
  --section-padding-bottom: 7.2rem;
}

@font-face {
  font-family: poppins;
  src: url("poppins-black-webfont.a6adaedc.woff2") format("woff2"), url("poppins-black-webfont.30aca685.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: poppins;
  src: url("poppins-blackitalic-webfont.37169caf.woff2") format("woff2"), url("poppins-blackitalic-webfont.ef1cf4af.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: poppins;
  src: url("poppins-bold-webfont.d33a3b16.woff2") format("woff2"), url("poppins-bold-webfont.058290b4.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: poppins;
  src: url("poppins-bolditalic-webfont.29f6a682.woff2") format("woff2"), url("poppins-bolditalic-webfont.dbd7038e.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: poppins;
  src: url("poppins-extrabold-webfont.c06ab3f7.woff2") format("woff2"), url("poppins-extrabold-webfont.cf351498.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: poppins;
  src: url("poppins-extrabolditalic-webfont.91dd85be.woff2") format("woff2"), url("poppins-extrabolditalic-webfont.9c3a1705.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: poppins;
  src: url("poppins-regular-webfont.04a00019.woff2") format("woff2"), url("poppins-regular-webfont.e524a660.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: poppins;
  src: url("poppins-italic-webfont.4ac2a61d.woff2") format("woff2"), url("poppins-italic-webfont.d9dd710f.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: poppins;
  src: url("poppins-light-webfont.eb075664.woff2") format("woff2"), url("poppins-light-webfont.efd03076.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: poppins;
  src: url("poppins-lightitalic-webfont.6dbd79be.woff2") format("woff2"), url("poppins-lightitalic-webfont.c52b76e2.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: poppins;
  src: url("poppins-medium-webfont.5ac9ebeb.woff2") format("woff2"), url("poppins-medium-webfont.ba29f660.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: poppins;
  src: url("poppins-mediumitalic-webfont.d698c6f7.woff2") format("woff2"), url("poppins-mediumitalic-webfont.8845c830.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: Satoshi;
  src: url("Satoshi-Bold.9042e65d.woff2") format("woff2"), url("Satoshi-Bold.d6123eb6.woff") format("woff"), url("Satoshi-Bold.642f978f.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Satoshi;
  src: url("Satoshi-Light.7f02ab08.woff2") format("woff2"), url("Satoshi-Light.0a6976bf.woff") format("woff"), url("Satoshi-Light.db4cda7c.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Satoshi;
  src: url("Satoshi-Medium.5be63f72.woff2") format("woff2"), url("Satoshi-Medium.de33c0c3.woff") format("woff"), url("Satoshi-Medium.0cd9b7ce.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Satoshi;
  src: url("Satoshi-Regular.5cd7338f.woff2") format("woff2"), url("Satoshi-Regular.23214b96.woff") format("woff"), url("Satoshi-Regular.e855a0d3.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

.menu .main-nav-link {
  letter-spacing: .5px;
  text-rendering: geometricprecision;
  font-family: poppins;
  font-size: 1.6rem;
  font-weight: 500;
}

.paragraph {
  text-rendering: geometricprecision;
  font-family: poppins;
  font-size: 2rem;
  font-weight: 400;
}

@media only screen and (max-width: 60em) {
  .paragraph {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .paragraph {
    font-size: 1.6rem;
  }
}

.paragraph--big {
  letter-spacing: -.2px;
  text-rendering: geometricprecision;
  font-family: poppins;
  font-size: 2.4rem;
  font-weight: 300;
}

@media only screen and (max-width: 60em) {
  .paragraph--big {
    font-size: 2.2rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .paragraph--big {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .paragraph--big {
    font-size: 1.8rem;
  }
}

.heading--big {
  text-transform: uppercase;
  letter-spacing: 0;
  text-rendering: geometricprecision;
  font-family: satoshi;
  font-size: 12rem;
  font-weight: 700;
  line-height: 1.1;
}

@media only screen and (max-width: 80em) {
  .heading--big {
    font-size: 10rem;
  }
}

@media only screen and (max-width: 60em) {
  .heading--big {
    font-size: 8rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .heading--big {
    font-size: 7.2rem;
  }
}

@media only screen and (max-width: 43.75em) {
  .heading--big {
    font-size: 6.4rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading--big {
    font-size: 5.6rem;
  }
}

@media only screen and (max-width: 31.25em) {
  .heading--big {
    font-size: 4.6rem;
  }
}

@media only screen and (max-width: 26.25em) {
  .heading--big {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 23.125em) {
  .heading--big {
    font-size: 3.6rem;
  }
}

.btn-2, .btn {
  text-rendering: geometricprecision;
  font-family: poppins;
  font-size: 1.8rem;
  font-weight: 400;
}

@media only screen and (max-width: 56.25em) {
  .btn-2, .btn {
    font-size: 1.6rem;
  }
}

.label {
  color: #3cb371;
  text-rendering: geometricprecision;
  font-family: satoshi;
  font-size: 2.8rem;
  font-weight: bold;
}

.section__heading {
  text-rendering: geometricprecision;
  text-transform: uppercase;
  color: #000;
  font-family: satoshi;
  font-size: 5.6rem;
  font-weight: 700;
  position: relative;
}

@media only screen and (max-width: 60em) {
  .section__heading {
    font-size: 4.8rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .section__heading {
    font-size: 4rem;
  }
}

.section__heading:after {
  content: "";
  background-color: gray;
  width: 10rem;
  height: 2px;
  margin-top: 2rem;
  display: block;
}

.quote {
  text-align: center;
  text-rendering: geometricprecision;
  font-family: satoshi;
  font-size: 6rem;
  font-weight: 500;
  line-height: 1.2;
}

@media only screen and (max-width: 56.25em) {
  .quote {
    font-size: 4.8rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .quote {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 26.25em) {
  .quote {
    font-size: 3.6rem;
  }
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-size: 62.5%;
}

a {
  color: currentColor;
  text-decoration: none;
}

ul {
  list-style: none;
}

picture, img {
  display: block;
}

input {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
  background: none;
  border: none;
}

.contact, .lieu, .about {
  padding-top: var(--section-padding-top);
  padding-bottom: var(--section-padding-bottom);
}

.section__title, .footer, .contact__row-1, .lieu__row-6, .lieu__row-5, .lieu__row-4, .lieu__row-3, .lieu__row-2, .lieu__row-1, .about__row-4, .about__row-3, .about__row-2, .about__row-1, .cta, .header {
  padding: 0 var(--body-horizontal-padding);
}

.footer .footer-content, .contact .row__container, .lieu__row-6 .row__container, .lieu__row-5 .row__container, .lieu__row-4 .row__container, .lieu__row-3 .row__container, .lieu__row-2 .row__container, .lieu__row-1 .row__container, .about__row-4 .row__container, .about__row-3 .row__container, .about__row-2 .row__container, .about__row-1 .row__container, .cta__container, .header__container, .section__heading {
  max-width: 150rem;
  margin: 0 auto;
}

@media only screen and (max-width: 64em) {
  .nav-open {
    height: 100vh;
    overflow: hidden;
  }
}

.header {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  transition: all .2s ease-in-out;
  position: fixed;
  top: 0;
}

.scroll-down .header {
  transform: translateY(-100%);
}

.header__container {
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;
  display: flex;
}

.header__home-link img {
  width: 15.7rem;
  height: auto;
}

@media only screen and (max-width: 43.75em) {
  .header__home-link img {
    width: 12rem;
  }
}

@media only screen and (max-width: 64em) {
  .header__menu {
    z-index: 10;
    visibility: hidden;
    background-color: #f0fff0;
    width: 100%;
    height: calc(100vh - 9.8rem);
    position: fixed;
    top: 9.8rem;
    right: 0;
    overflow-x: hidden;
    transform: translateX(100%);
  }
}

@media only screen and (max-width: 64em) and (max-width: 43.75em) {
  .header__menu {
    height: calc(100vh - 11.8rem);
    top: 11.8rem;
  }
}

@media only screen and (max-width: 64em) {
  .header__menu.with-transition {
    transition: transform .2s ease-in-out, visibility .2s ease-in-out;
  }

  .nav-open .header__menu {
    visibility: visible;
    display: initial;
    transition: transform .2s ease-in-out, visibility .2s ease-in-out;
    transform: translateX(0%);
  }
}

.menu {
  column-gap: 4rem;
  display: flex;
}

@media only screen and (max-width: 64em) {
  .menu {
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 8rem;
    margin-top: 12rem;
    margin-left: 3.6rem;
  }
}

.menu .main-nav-link {
  transition: all .3s ease-in-out;
  display: inline-block;
  transform: scale(1);
}

@media only screen and (max-width: 64em) {
  .menu .main-nav-link {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 31.25em) {
  .menu .main-nav-link {
    font-size: 2.8rem;
  }
}

@media (hover: hover) {
  .menu .main-nav-link:hover {
    color: green;
    transform: translateY(-3px);
  }
}

.mobile-nav__btn {
  display: none;
}

@media only screen and (max-width: 64em) {
  .mobile-nav__btn {
    z-index: 11;
    display: block;
  }
}

@media only screen and (max-width: 26.25em) {
  .mobile-nav__btn {
    margin-right: 1rem;
  }
}

.mobile-nav__icon {
  color: #222;
  width: 3rem;
  height: 3rem;
  transition: all .3s ease-in-out;
}

@media (hover: hover) {
  .mobile-nav__icon:hover {
    transform: scale(1.15);
  }
}

.mobile-nav__icon--close {
  display: none;
}

.nav-open .mobile-nav__icon--close {
  display: block;
}

.nav-open .mobile-nav__icon--open {
  display: none;
}

main {
  margin-top: 14.5rem;
}

@media only screen and (max-width: 43.75em) {
  main {
    margin-top: 11.8rem;
  }
}

.cta__container {
  flex-direction: column;
  align-items: center;
  padding: 7.2rem 0;
  display: flex;
}

.cta__row {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-items: start;
  display: grid;
}

.cta .label {
  text-align: center;
}

.cta .heading--big {
  text-align: center;
  margin-bottom: 1.6rem;
}

.cta .paragraph--big {
  text-align: center;
  margin-bottom: 4.8rem;
}

.cta .img-container {
  aspect-ratio: 1;
  background-color: #f0fff0;
  width: 80%;
}

@media only screen and (max-width: 31.25em) {
  .cta .img-container {
    width: 100%;
  }
}

.cta .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.cta .cta__links {
  column-gap: 2.4rem;
  margin-bottom: 10rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .cta .cta__links {
    flex-direction: column;
    gap: 1.8rem 0;
  }
}

.cta p {
  width: 50%;
  margin-top: 6rem;
}

.about__row-1 {
  margin-bottom: 6rem;
}

.about__row-1 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .about__row-1 .row__container {
    row-gap: 4.8rem;
  }
}

.about__row-1 .img-container {
  aspect-ratio: 4 / 3;
  background-color: #f0fff0;
  grid-column: 1 / 6;
}

@media only screen and (max-width: 56.25em) {
  .about__row-1 .img-container {
    grid-column: 1 / 8;
  }
}

@media only screen and (max-width: 37.5em) {
  .about__row-1 .img-container {
    grid-column: 1 / 13;
  }
}

.about__row-1 .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.about__row-1 .paragraph {
  grid-column: 8 / 12;
  margin-top: 2.4rem;
}

@media only screen and (max-width: 60em) {
  .about__row-1 .paragraph {
    grid-column: 8 / 13;
  }
}

@media only screen and (max-width: 56.25em) {
  .about__row-1 .paragraph {
    grid-column: 5 / 13;
  }
}

@media only screen and (max-width: 37.5em) {
  .about__row-1 .paragraph {
    grid-column: 1 / 13;
  }
}

.about__row-1 .paragraph span {
  font-weight: 600;
}

.about__row-2 {
  margin-bottom: 16rem;
}

.about__row-2 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .about__row-2 .row__container {
    grid-template-rows: repeat(2, min-content);
    row-gap: 4.8rem;
  }
}

.about__row-2 .img-container {
  aspect-ratio: 1;
  background-color: #f0fff0;
  grid-column: 5 / 12;
}

@media only screen and (max-width: 37.5em) {
  .about__row-2 .img-container {
    grid-column: 1 / 13;
  }
}

.about__row-2 .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.about__row-2 .paragraph {
  grid-column: 1 / 5;
  align-self: center;
}

@media only screen and (max-width: 56.25em) {
  .about__row-2 .paragraph {
    grid-area: 2 / 1 / auto / 9;
  }
}

@media only screen and (max-width: 37.5em) {
  .about__row-2 .paragraph {
    grid-column: 1 / 13;
  }
}

.about__row-3 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

.about__row-3 .quote {
  grid-column: 1 / 13;
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 37.5em) {
  .about__row-3 .quote {
    width: 100%;
  }
}

.about__row-3 .paragraph {
  grid-column: 4 / 13;
  align-self: center;
  margin-top: 10rem;
}

@media only screen and (max-width: 56.25em) {
  .about__row-3 .paragraph {
    grid-column: 2 / 13;
  }
}

@media only screen and (max-width: 37.5em) {
  .about__row-3 .paragraph {
    grid-column: 1 / 13;
  }
}

.about__row-4 {
  margin-top: 10rem;
}

.about__row-4 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: 5.6rem 2.4rem;
  display: grid;
}

.about__row-4 .row__container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.about__row-4 .img-container {
  aspect-ratio: 1;
  background-color: #f0fff0;
  grid-area: 1 / 3 / auto / 11;
}

@media only screen and (max-width: 37.5em) {
  .about__row-4 .img-container {
    grid-column: 1 / 13;
  }
}

.about__row-4 .paragraph {
  grid-area: 2 / 1 / auto / 9;
  align-self: center;
  margin-top: 6rem;
}

@media only screen and (max-width: 43.75em) {
  .about__row-4 .paragraph {
    grid-column: 1 / 12;
  }
}

@media only screen and (max-width: 37.5em) {
  .about__row-4 .paragraph {
    grid-column: 1 / 13;
  }
}

.about__row-4 .paragraph span {
  font-weight: 700;
}

.lieu__row-1 {
  margin-bottom: 6rem;
}

.lieu__row-1 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-1 .row__container {
    row-gap: 4.8rem;
  }
}

.lieu__row-1 .img-container {
  aspect-ratio: 4 / 3;
  background-color: #f0fff0;
  grid-column: 1 / 6;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-1 .img-container {
    grid-column: 1 / 8;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-1 .img-container {
    grid-column: 1 / 13;
  }
}

.lieu__row-1 .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.lieu__row-1 .paragraph {
  grid-column: 8 / 12;
  margin-top: 2.4rem;
}

@media only screen and (max-width: 60em) {
  .lieu__row-1 .paragraph {
    grid-column: 8 / 13;
  }
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-1 .paragraph {
    grid-column: 5 / 13;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-1 .paragraph {
    grid-column: 1 / 13;
  }
}

.lieu__row-2 {
  margin-bottom: 16rem;
}

.lieu__row-2 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-2 .row__container {
    grid-template-rows: repeat(2, min-content);
    row-gap: 4.8rem;
  }
}

.lieu__row-2 .img-container {
  aspect-ratio: 1;
  background-color: #f0fff0;
  grid-column: 5 / 12;
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-2 .img-container {
    grid-column: 1 / 13;
  }
}

.lieu__row-2 .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.lieu__row-2 .paragraph {
  grid-column: 1 / 5;
  align-self: center;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-2 .paragraph {
    grid-area: 2 / 1 / auto / 9;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-2 .paragraph {
    grid-column: 1 / 13;
  }
}

.lieu__row-3 {
  margin-bottom: 16rem;
}

.lieu__row-3 .quote {
  width: 80%;
  margin: 0 auto 6rem;
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-3 .quote {
    width: 100%;
  }
}

.lieu__row-3 .img-container {
  aspect-ratio: 16 / 9;
  background-color: #f0fff0;
  width: 80%;
  margin: 0 auto;
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-3 .img-container {
    width: 100%;
  }
}

.lieu__row-3 .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.lieu__row-3 .paragraph {
  width: 80%;
  margin: 2.4rem auto 0;
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-3 .paragraph {
    width: 100%;
  }
}

.lieu__row-4 {
  margin-bottom: 6rem;
}

.lieu__row-4 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-4 .row__container {
    grid-template-rows: repeat(2, min-content);
    row-gap: 4.8rem;
  }
}

.lieu__row-4 .img-container {
  aspect-ratio: 4 / 3;
  background-color: #f0fff0;
  grid-column: 1 / 6;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-4 .img-container {
    grid-area: 1 / 1 / auto / 9;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-4 .img-container {
    grid-column: 1 / 13;
  }
}

.lieu__row-4 .img-container video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.lieu__row-4 .paragraph {
  grid-column: 8 / 12;
  margin-top: 2.4rem;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-4 .paragraph {
    grid-area: 2 / 1 / auto / 9;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-4 .paragraph {
    grid-column: 1 / 13;
  }
}

.lieu__row-5 {
  margin-bottom: 16rem;
}

.lieu__row-5 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-5 .row__container {
    grid-template-rows: repeat(2, min-content);
    row-gap: 4.8rem;
  }
}

.lieu__row-5 .img-container {
  aspect-ratio: 16 / 9;
  background-color: #f0fff0;
  grid-column: 5 / 12;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-5 .img-container {
    grid-area: 1 / 5 / auto / 13;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-5 .img-container {
    grid-column: 1 / 13;
  }
}

.lieu__row-5 .img-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.lieu__row-5 .paragraph {
  grid-column: 1 / 5;
  align-self: center;
}

@media only screen and (max-width: 56.25em) {
  .lieu__row-5 .paragraph {
    grid-area: 2 / 5 / auto / 13;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-5 .paragraph {
    grid-column: 1 / 13;
  }
}

.lieu__row-6 {
  margin-bottom: 16rem;
}

.lieu__row-6 .row__container {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  column-gap: 2.4rem;
  display: grid;
}

.lieu__row-6 .quote {
  text-align: start;
  grid-column: 3 / 12;
  margin-bottom: 3.2rem;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .quote {
    grid-column: 2 / 12;
  }
}

@media only screen and (max-width: 37.5em) {
  .lieu__row-6 .quote {
    grid-column: 1 / 13;
  }
}

.lieu__row-6 .paragraph {
  grid-column: 3 / 10;
  margin-bottom: 8rem;
}

@media only screen and (max-width: 60em) {
  .lieu__row-6 .paragraph {
    grid-column: 3 / 12;
  }
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .paragraph {
    grid-column: 1 / 13;
  }
}

.lieu__row-6 .gallery {
  grid-gap: 1.5rem;
  grid-column: 1 / 13;
  grid-template-rows: repeat(7, min(12.6rem, 9vw));
  grid-template-columns: repeat(8, 1fr);
  display: grid;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery {
    grid-template-rows: repeat(7, min-content);
    grid-template-columns: 1fr;
  }
}

.lieu__row-6 .gallery__item {
  object-fit: cover;
  background-color: #f0fff0;
  width: 100%;
  height: 100%;
  display: block;
}

.lieu__row-6 .gallery__item--1 {
  grid-area: 1 / 1 / 3 / 3;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery__item--1 {
    grid-area: auto / 1 / auto / 2;
  }
}

.lieu__row-6 .gallery__item--2 {
  grid-area: 3 / 1 / 6 / 3;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery__item--2 {
    grid-area: auto / 1 / auto / 2;
  }
}

.lieu__row-6 .gallery__item--3 {
  grid-area: 6 / 1 / 8 / 3;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery__item--3 {
    grid-area: auto / 1 / auto / 2;
  }
}

.lieu__row-6 .gallery__item--4 {
  grid-area: 1 / 3 / 4 / 6;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery__item--4 {
    grid-area: auto / 1 / auto / 2;
  }
}

.lieu__row-6 .gallery__item--5 {
  grid-area: 4 / 3 / 8 / 6;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery__item--5 {
    grid-area: auto / 1 / auto / 2;
  }
}

.lieu__row-6 .gallery__item--6 {
  grid-area: 1 / 6 / 3 / 9;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery__item--6 {
    grid-area: auto / 1 / auto / 2;
  }
}

.lieu__row-6 .gallery__item--7 {
  grid-area: 3 / 6 / 8 / 9;
}

@media only screen and (max-width: 43.75em) {
  .lieu__row-6 .gallery__item--7 {
    grid-area: auto / 1 / auto / 2;
  }
}

.lieu__row-6 .gallery img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contact {
  background-color: #f0fff0;
  padding-bottom: 40rem;
}

.contact__content {
  flex-direction: column;
  align-items: start;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
}

.contact__content > * {
  padding: 2.4rem 0;
  position: relative;
}

.contact .row__container {
  padding-top: 6rem;
}

.contact p:first-child {
  font-weight: 700;
}

.contact p {
  display: inline-block;
}

.contact .adresse {
  flex-direction: column;
  align-items: start;
  row-gap: 1rem;
  display: flex;
}

.contact .tel {
  width: 100%;
}

.contact .tel:before {
  content: "";
  background-color: #000;
  width: 3rem;
  height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.contact .mail {
  width: 100%;
}

.contact .mail:before {
  content: "";
  background-color: #000;
  width: 3rem;
  height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.footer .footer-content {
  text-align: center;
  padding: 3.2rem 0;
}

.footer .paragraph {
  justify-content: center;
  font-weight: 300;
  display: flex;
}

@media only screen and (max-width: 43.75em) {
  .footer .paragraph {
    flex-direction: column;
  }
}

.footer .paragraph span:not(:last-child) {
  margin-right: 3rem;
}

.footer .paragraph span:nth-child(2n):after {
  content: "|";
}

@media only screen and (max-width: 43.75em) {
  .footer .paragraph span:nth-child(2n):after {
    content: "•";
  }
}

.btn {
  color: #000;
  background-color: #32cd32;
  border-radius: .8rem;
  padding: 1.6rem 2.8rem;
  transition: all .3s ease-out;
  display: inline-block;
}

.btn:hover {
  transform: translateY(-4px);
}

.btn-2 {
  color: #222;
  background-color: #0000;
  border: 1px solid #222;
  border-radius: 6rem;
  padding: 1.6rem 2.8rem;
  transition: all .3s ease-out;
  display: inline-block;
}

.btn-2:hover {
  transform: translateY(-4px);
}

.section__title {
  margin-bottom: 6rem;
}

.list {
  padding-top: 4rem;
  padding-left: 6rem;
}

@media only screen and (max-width: 31.25em) {
  .list {
    padding-left: 4rem;
  }
}

.list__item:not(:last-child) {
  margin-bottom: 1.2rem;
}

/*# sourceMappingURL=index.69ced1aa.css.map */
